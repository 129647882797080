import { Component, createRef } from "preact";
import axios from 'axios';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from '../actions';
import { withRouter } from 'react-router';
import _ from "lodash";
import * as helpers from "@cargo/common/helpers";
import selectors from "../selectors";
import Page from "./page";
import { overlayDefaults } from "../../../admin/src/defaults/overlay-defaults";
import { memoizeWeak } from "../helpers";
import windowInfo from "./window-info"
import withStore from '../withStore';

const defaultContentPad = {top: 0, bottom: 0};

let activeOverlays = new Array();

export const overlayMethods = {
	closeOverlay: () => {},
	openOverlay: () => {},
	getOverlay: () => {},
	toggleOverlay: () => {},
	handleGlobalClick: () => {},
	getAllOverlays: () => {}
}

class OverlayController extends Component {

	constructor(props){

		super(props);

		this.state = {
			activeOverlays: [],
		};

		// use this to manage overlay state in between component render cycles as preact
		// only updates this.state after a render, which causes us to look at old state when
		// making more than one change to this in the same render cycle
		this.intermediateActiveOverlays = this.state.activeOverlays;

		overlayMethods.openOverlay = this.openOverlay;
		overlayMethods.closeOverlay = this.closeOverlay;
		overlayMethods.toggleOverlay = this.toggleOverlay;
		overlayMethods.getOverlay = this.getOverlay;
		overlayMethods.handleGlobalClick = this.handleGlobalClick;
		overlayMethods.getAllOverlays = this.getAllOverlays;

	}

	componentDidMount() {
		this.autoRenderOverlays();
	}

	autoRenderOverlays = (previousAutorenderedOverlays = []) => {

		if(this.props.adminMode) {

			const allowedPIDs = [this.props.PIDToEdit, this.props.overlayBeingPreviewed].filter(val => !!val);

			// if one of the auto rendereable overlays is being edited, force it open
			if(this.props.autoRenderableOverlays.some(page => allowedPIDs.includes(page.id))) {
				this.openOverlay(this.props.PIDToEdit);
			}

			// close all other overlays
			this.state.activeOverlays.forEach(overlay => {
				
				if(!allowedPIDs.includes(overlay.pid)) {
					this.closeOverlay(overlay?.pid)
				}

			})

			return;
		}

		const curr = this.props.autoRenderableOverlays.map(page => page.id);
		const prev = previousAutorenderedOverlays.map(page => page.id);

		const removed = _.difference(prev, curr);
		const added = _.difference(curr, prev);

		removed.forEach(pid => {
			this.closeOverlay(pid)
		});

		// stack auto rendered overlays by their page list sort index
		added.sort((a, b) => {
			return this.props.sortMap[b] - this.props.sortMap[a];
		});

		added.forEach(pid => {
			this.openOverlay(pid)
		});

	}

	componentDidUpdate = (prevProps, prevState) => {

		if(
			this.props.autoRenderableOverlays !== prevProps.autoRenderableOverlays
			|| this.props.adminMode && !prevProps.adminMode
		) {
			this.autoRenderOverlays(prevProps.autoRenderableOverlays);
		}

		if(this.props.adminMode !== prevProps.adminMode) {
			// in admin mode we'll render the pid needing editing,
			// if outside admin mode we should render all auto rendereable overlays
			// without looking at previously open overlays, so pass an empty array here.
			this.autoRenderOverlays([]);
		}

		if(this.state.activeOverlays !== prevState.activeOverlays) {

			const added = _.difference(this.state.activeOverlays, prevState.activeOverlays);
			const removed = _.difference(prevState.activeOverlays, this.state.activeOverlays);

			activeOverlays = [...this.state.activeOverlays];

			assignTopmostScrollableOverlay();

			if (added.length > 0) {
				for (const addedOverlay of added) {
					if (addedOverlay.getOverlayOptions().animateOnOpen || addedOverlay.getOverlayOptions().animateOnClose) {
						addedOverlay.ref?.current?.base.addEventListener('animationend', this.handleAnimationEnd);
					}
				}
			}

		}

	}

	componentWillUnmount = () => {
	}

	handleGlobalClick = (target, originalTarget) => {

		if (this.props.adminMode) {
			// do not close the overlay being edited
			return;
		}

		const closestQuickView = originalTarget.closest('.quick-view');
		if( closestQuickView){
			return
		}		

		// first check to see if we clicked inside .page-content so we do not include page padding
		const closestPid = originalTarget.closest('.page-content')?.closest('.page')?.getAttribute('id');
		const closestOverlay = this.state.activeOverlays.find(overlay => overlay.pid === closestPid);
		const topOverlay = this.state.activeOverlays[this.state.activeOverlays.length - 1];
		const state = this.props.store.getState();

		const targetIsInsideOverlay = this.target && this.target.closest('.overlay-content') ? true : false;
		const targetIsOverlay = Object.keys(state?.pages?.byId).some((pid) => {
			const page = state.pages.byId[pid];
			if (!page.overlay) {
				return false;
			}
			const targetPurl = target.getAttribute('href')?.replace(/\//, '');
			return page.purl === targetPurl 
		});

		const topOverlayPurl = state.pages.byId[topOverlay?.pid]?.purl;
		const targetPurl = target?.getAttribute('href')?.replace(/\//, '');

		// If navigating, close any overlays that have closeOnNavigate set to true
		if (
			// If even one of the activeOverlays has closeOnNavigate set to true
			this.state.activeOverlays.some(overlay => overlay.getOverlayOptions().closeOnNavigate)
			// if the target is an anchor tag
			&& target.hasAttribute('href')
			// if the target is has a rel of history or home-page
			&& (
				target.getAttribute('rel') === "history" || target.getAttribute('rel') === "home-page"
			)
			// if navigating to the contact from 
			&& target.getAttribute('href').replace(/\//, '').toLowerCase() !== 'contact-form'
			// if navigating out of the contact form
			&& !state.frontendState.contactForm.inited
			// if navigating out of the cart
			&& !state.frontendState.cartOpen
			// if target does not direct to an overlay
			&& targetIsOverlay === false
		) {
			// when navigating, attempt to wait till a potential deferred content render has completed.
			// first wait for router to handle new route...
			requestAnimationFrame(() => {

				// We are awaiting page data
				if(window.__deferred_page_load_promise__) {

					// wait for the page data to load first
					window.__deferred_page_load_promise__.then(() => {
						// wait another frame so the content is fully rendered
						requestAnimationFrame(() => {
							// The page is rendered, close all overlays that have closeOnNavigate set to true
							this.state.activeOverlays.forEach(overlay => {
								if (overlay.getOverlayOptions().closeOnNavigate) {
									this.closeOverlay(overlay.pid);
								}
							});
						});
					});

				} else {

					// If navigate originated inside an overlay, only close that overlay
					if (closestOverlay) {
						this.closeOverlay(closestOverlay);
					} else {
						// If navigation originated outside an overlay, close all overlays that have closeOnNavigate set to true if no closest overlay
						this.state.activeOverlays.forEach(overlay => {
							if (overlay.getOverlayOptions().closeOnNavigate) {
								this.closeOverlay(overlay.pid);
							}
						});
					}

				}

			});

			return;
		}

		// If clicking out of an overlay, close it
		if(
			topOverlay
			&& topOverlay.getOverlayOptions().closeOnClickout === true
			&& !state.frontendState.contactForm.inited
			&& !state.frontendState.cartOpen
			&& closestPid !== topOverlay?.pid
			&& target.tagName !== 'A'
		) {
			this.closeOverlay(topOverlay?.pid);
			return;
		}

	}

	handleAnimationEnd = (e) => {
		if (!e.target.classList.contains('page')) {
			return;
		}

		switch (e.animationName) {
			case 'overlayOpen':
			case 'overlayOpenWithoutOpacity': {
				let overlayParent = e.target.closest('.overlay-animating');
				if( overlayParent ){
					overlayParent?.classList?.remove('overlay-animating');
				}
				e.target.classList.remove('overlay-open');
				break;
			}
			case 'overlayClose':
			case 'overlayCloseWithoutOpacity': {
				setTimeout(()=>{
					let overlayParent = e.target.closest('.overlay-animating');
					if( overlayParent ){
						overlayParent?.classList?.remove('overlay-animating');
					}
				}, 101);
				e.target.classList.remove('overlay-close');
				const pid = e.target.getAttribute('id');
				this.closeOverlay(pid, true);
				break;
			}
		}

	}

	openOverlay = (pid, additionalOverlayOptions = {}) => {

		if(!pid || this.getOverlay(pid)) {
			// prevent double overlays
			return;
		}

		this.intermediateActiveOverlays = [
			...this.intermediateActiveOverlays,
			{
				pid,
				ref: createRef(),
				getOverlayOptions: () => assembleOverlayOptions(pid, this.props.overlayOptionsByPID[pid], additionalOverlayOptions)
			}
		];

		this.setState({
			activeOverlays: this.intermediateActiveOverlays
		})

	}

	closeOverlay = (pid, skipTransition = false) => {

		if (!pid) {
			return;
		}

		if (skipTransition === false) {

			const overlay = this.getOverlay(pid);
			if (!overlay) {
				return;
			}

			const overlayOptions = overlay.getOverlayOptions();
			if (overlayOptions?.animateOnClose?.speed > 0) {
				overlay.ref.current.base.classList.add('overlay-close');
				overlay.ref.current.base.classList.add('overlay-animating');
				return;
			}

		}

		this.intermediateActiveOverlays = this.intermediateActiveOverlays.filter(overlay => overlay.pid !== pid);

		this.setState({
			activeOverlays: this.intermediateActiveOverlays
		});

	}

	toggleOverlay = (pid, additionalOverlayOptions) => {

		if(this.getOverlay(pid)) {
			this.closeOverlay(pid);
		} else {
			this.openOverlay(pid, additionalOverlayOptions);
		}

	}

	getAllOverlays = () => {
		return this.intermediateActiveOverlays;
	}

	getOverlay = (pid) => {
		return this.intermediateActiveOverlays.find(overlay => overlay.pid === pid);
	}

	render() {

		return this.state.activeOverlays.map(({pid, getOverlayOptions, ref}) => {

			return <Page 
				key={pid} 
				id={pid} 
				contentPad={defaultContentPad}
				isOverlay={true}
				overlayOptions={getOverlayOptions()}
				ref={ref}
			/>

		});

	}
}

const assembleOverlayOptions = memoizeWeak((pid, pageOverlayOptions, additionalOverlayOptions) => {

	return _.merge(
		{}, 
		// populate defaults
		overlayDefaults,
		// overwrite defaults with any overlay_options set on the page
		pageOverlayOptions,
		// Overwrite defaults and page options with any overlay options passed to openOverlay
		additionalOverlayOptions
	);

})

const getOverlayOptionsByPID = memoizeWeak(pagesById => {
	return pagesById = _.mapValues(pagesById, page => page.overlay_options)
});



if( !helpers.isServer){

	// when initializing a wheel event, we want to 'latch' on to the initial element that's being scrolled
	// whether a normal page or the overlay itself
	// so as to avoid the wheel target jumping while scrolling

	var unlatchTimeout = null;
	var scrollableOverlay = null;
	var latchedScrollTarget = false;
	var cancelEvent = false;
	var lastTouch = null;

	var assignScrollableTarget = (target)=>{

		clearTimeout(unlatchTimeout);

		// 200 after last touch/wheel, allow changing of target
		unlatchTimeout = setTimeout(()=>{
			latchedScrollTarget = false;
			cancelEvent = false;
		}, 200);


		if( !latchedScrollTarget ){

			// in reverse order so the first one found is the topmost overlay
			const overlayElements = [...activeOverlays].reverse().map((overlay)=>{
				return document.getElementById(overlay.pid)?.closest('.overlay-content');
			})

			const scrolledInOverlay = overlayElements.find(overlayEl=>overlayEl?.contains(target));
			scrollableOverlay = null;

			// no wheeled-in overlay? check the top active overlay for an element that could use a scroll
			if( !scrolledInOverlay ){

				const potentiallyScrollableOverlay = overlayElements.find((overlayEl,index)=>{

					return overlayEl.classList?.contains('overlay-allow-scroll');

				}) 

				if( potentiallyScrollableOverlay ){

					scrollableOverlay = potentiallyScrollableOverlay;
					cancelEvent = true;

				} else {

					cancelEvent = false;
					scrollableOverlay = null;
					
				}

		
			// if the wheeled-in overlay is scrollable, specifically redirect scroll to that element
			// this would just be native behavior except that safari doesn't latch to elements correctly
			} else if( scrolledInOverlay.classList.contains('overlay-allow-scroll') ){

				scrollableOverlay = scrolledInOverlay
				cancelEvent = true;

			} else {

				const scrollableOverlayAtTopLevel = overlayElements[0].classList?.contains('overlay-allow-scroll') ? overlayElements[0] : null;

				if( scrollableOverlayAtTopLevel ){

					scrollableOverlay = scrollableOverlayAtTopLevel;
					cancelEvent = true;

				} else {

					const interactedInContentArea = scrolledInOverlay.querySelector('.page-content')?.contains(target);

					// if we wheeled in the content area of a non-scrolling overlay with a solid background
					// don't scroll any overlay and cancel the wheel

					const hasBackdrop = scrolledInOverlay.querySelector('.backdrop');

					// if scrollable overlay has a background, block scrolling full stop
					if( (!interactedInContentArea && !scrolledInOverlay.classList.contains('is-passthrough-overlay') || hasBackdrop) ){
						cancelEvent = true;
						scrollableOverlay = null;

					// if interacted inside the content area and we have a background color, also cancel
					} else if ( interactedInContentArea && !scrolledInOverlay.classList.contains('is-content-passthrough-overlay') ){
						cancelEvent = true;
						scrollableOverlay = null;

					} else {
						cancelEvent = false;
						scrollableOverlay = null;
					}					

				}


			}

			latchedScrollTarget = true;		

		}

	}

	let delta = 0;
	let smoothedDelta = 0;
	let resetDeltaTimeout = null;
	let animatedScrollElement = null;
	let lastTimestamp;
	let scrollInterventionAnimationFrame = null;
	let initialDocumentScroll = 0;
	let initialTouchPosition = null;
	let touchedInOverlay = false;
	let clickHappened = false;
	let documentScrollingIsFrozen = false;
	let scrollStoppedCounter = 0;

	var handleTouchMove = (e)=>{

		delta = lastTouch - e.touches[0].clientY;

		smoothedDelta = smoothedDelta*.8 + delta*.2;

		lastTouch = e.touches[0].clientY;


		clearTimeout(resetDeltaTimeout);
		resetDeltaTimeout = setTimeout(()=>{
			delta = 0;
		}, 300);

		if( scrollableOverlay ){
			scrollableOverlay.scrollTop = scrollableOverlay.scrollTop + delta;	
		}

		if (cancelEvent || touchedInOverlay ){
			cancelEvent = true;
			e.preventDefault();
			e.stopPropagation();
		}

		if( cancelEvent){
			document.scrollingElement.scrollTop = initialDocumentScroll;
		}


	}

	var handleWheel = (e)=>{

		delta = e.deltaY;
		assignScrollableTarget(e.target);
	
		if (cancelEvent ){
			e.preventDefault();
		}

		if( scrollableOverlay ){			
			scrollableOverlay.scrollTop = scrollableOverlay.scrollTop + delta;	
		} 		
		
	}

	var freezeDocumentScrolling = ()=>{
		if( documentScrollingIsFrozen){
			return
		}
		documentScrollingIsFrozen = true;
		initialDocumentScroll = document.scrollingElement.scrollTop;

		document.scrollingElement.style.overflow = 'hidden';
		document.scrollingElement.style.overscrollBehavior = 'none';
		document.scrollingElement.scrollTop = initialDocumentScroll;	
	}

	var unFreezeDocumentScrolling = _.debounce(()=>{
		if( !documentScrollingIsFrozen){
			return
		}
		documentScrollingIsFrozen = false;		
		document.scrollingElement.style.overflow = '';
		document.scrollingElement.style.overscrollBehavior = '';
		document.scrollingElement.scrollTop = initialDocumentScroll;			
	}, 150);



	// animate the 'inertia' scroll 
	var scrollInterventionAnimation = (timestamp)=>{

		// stop animation because we started a touch or the inertia-scroll ended
		if( !animatedScrollElement){
			unFreezeDocumentScrolling();
			return;
		}

		const timeDelta = lastTimestamp == 0 ? 16 : timestamp - lastTimestamp;

		lastTimestamp = timestamp;

		smoothedDelta = smoothedDelta *.95;
		
		const currentScrollTop = animatedScrollElement.scrollTop;
		animatedScrollElement.scrollTop = animatedScrollElement.scrollTop+(smoothedDelta*(timeDelta/16.667));

		// if we're animating against a non-scrolling edge, start a counter
		const scrollMotion =  animatedScrollElement.scrollTop - currentScrollTop;

		if( scrollMotion != 0 ){
			scrollStoppedCounter = 0;
		} else {
			scrollStoppedCounter++;
		}

		scrollInterventionAnimationFrame = requestAnimationFrame(scrollInterventionAnimation);

		if( Math.abs(smoothedDelta) < 0.5 || scrollStoppedCounter > 15 ){
			animatedScrollElement = null;
		}		
	}

	window.addEventListener('wheel', handleWheel, {passive: false});
	

	window.addEventListener('touchstart', (e)=>{

		scrollStoppedCounter = 0;
		clickHappened = false;
		animatedScrollElement = null;

		cancelAnimationFrame(scrollInterventionAnimationFrame);
		assignScrollableTarget( e.touches[0].target);
		smoothedDelta = 0;
		delta = 0;
		initialTouchPosition = e.touches[0].clientY;

		touchedInOverlay = e.touches[0].target.closest('.overlay-content')

		if( touchedInOverlay){

			const interactedInContentArea = touchedInOverlay.querySelector('.page-content')?.contains(e.touches[0].target);
			const hasBackdrop = touchedInOverlay.querySelector('.backdrop');

			// if scrollable overlay has a background, block scrolling full stop
			if(
				(!interactedInContentArea && touchedInOverlay.classList.contains('is-passthrough-overlay') && !hasBackdrop) ||
				(interactedInContentArea && touchedInOverlay.classList.contains('is-content-passthrough-overlay'))
			){
				touchedInOverlay = null;
			}
		}

		if( scrollableOverlay || touchedInOverlay) {

			freezeDocumentScrolling();

			lastTouch = e.touches[0].clientY;
			document.body.addEventListener('touchmove', handleTouchMove, {passive: false});			
			
		}

	});

	document.body.addEventListener('click', (e)=>{

		clickHappened = true;
	});

	document.body.addEventListener('touchend', (e)=>{

		document.body.removeEventListener('touchmove', handleTouchMove, {passive: false});
		if( scrollableOverlay && Math.abs(smoothedDelta) > 2){			
			animatedScrollElement = scrollableOverlay;
			clearTimeout(resetDeltaTimeout);
			lastTimestamp = window?.performance?.now?.() ?? 0;
			smoothedDelta = delta *.8 + smoothedDelta*.2;
			scrollInterventionAnimationFrame = requestAnimationFrame(scrollInterventionAnimation);
		} else {
			unFreezeDocumentScrolling();
		}

		const deltaFromInitial = initialTouchPosition - e.changedTouches[0].clientY;

		// if we're not animating any overlay scrolls and the overall touch move was very small but the touchmove was canceled, trigger it manually
		// the click event is triggered 300ms after the touchend in cases where we want to capture this
		if(!animatedScrollElement && Math.abs(deltaFromInitial) < 10 && cancelEvent ){
			const target = e.changedTouches[0].target;



			setTimeout(()=>{
				if( !clickHappened ){
					overlayMethods.handleGlobalClick(target, target);
				}

			}, 330);

		}

	}, {passive: false});


		
	windowInfo.on('window-resize', ()=>{
		assignTopmostScrollableOverlay();
	})

	var assignTopmostScrollableOverlay = _.debounce(()=>{


		// in reverse order so the first one found is the topmost overlay
		const reversedOverlays = [...activeOverlays].reverse();
		let topmostScrollableOverlayIndex = reversedOverlays.findIndex((overlay)=>{
			const overlayEl = overlay.ref?.current?.base.closest('.overlay-content');
			if( !overlayEl){
				return false
			}
			return overlayEl.classList.contains('overlay-allow-scroll') && !overlayEl.classList.contains('overlay-close');
		});



		reversedOverlays.forEach((overlay, index)=>{
			const overlayEl = overlay.ref?.current?.base.closest('.overlay-content');

			if( !overlayEl){
				return;
			}

			if(index==0){
				overlayEl.classList.add('top-overlay');
			} else {
				overlayEl.classList.remove('top-overlay');
			}

			if( topmostScrollableOverlayIndex == -1){
				overlayEl.classList.remove('top-scrollable-overlay');				
				overlayEl.classList.remove('behind-top-scrollable-overlay');					
			} else if( index > topmostScrollableOverlayIndex){
				overlayEl.classList.remove('top-scrollable-overlay');
				overlayEl.classList.add('behind-top-scrollable-overlay');
			} else if ( index == topmostScrollableOverlayIndex){
				overlayEl.classList.add('top-scrollable-overlay');
				overlayEl.classList.remove('behind-top-scrollable-overlay');
			} else {
				overlayEl.classList.remove('top-scrollable-overlay');				
				overlayEl.classList.remove('behind-top-scrollable-overlay');				
			}

		});


		if( topmostScrollableOverlayIndex > -1){
			document.body.classList.add('has-scrollable-overlay');
		} else {
			document.body.classList.remove('has-scrollable-overlay');
		}

	}, 30);

	assignTopmostScrollableOverlay();

}



function mapReduxStateToProps(state, ownProps) {

	return {
		adminMode : state.frontendState.adminMode,
		PIDToEdit : state.frontendState.PIDToEdit,
		overlayBeingPreviewed : state.frontendState.overlayBeingPreviewed,
		overlayOptionsByPID: getOverlayOptionsByPID(state.pages.byId),
		sortMap: state.structure.bySort,
		autoRenderableOverlays: selectors.getAutoRenderableOverlaysForSet(state, ownProps.pinContextPID, ownProps.match?.params?.page)
	};

}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		fetchContent: actions.fetchContent,
	}, dispatch);
}

export default withStore(
	withRouter(
		connect(
			mapReduxStateToProps,
			mapDispatchToProps
		)(
			OverlayController
		)
	)
);

export {activeOverlays}
